.tableTax table{
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
.styleTableTax{
    width: 100%;
    text-align: center;
}
.styleTableTax tr{
    border: 1px solid #ccc;
}
.styleTableTax thead{
    background-color:#d3d3d3;
    color: #000;
}
