.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    padding: 0 0 0 11px;
}
.ant-row.similar-row-parent{
    margin-top: 10px;
    padding-top: 10px;
}
.ant-row.section-prod-parent .table-container-de{
    height: 250px;
    overflow: auto;
    background-color: #fafafa;
}
.table-container-de-2{
    height: 560px;
    overflow: auto;
    background-color: #fafafa;
}

.wrapper-promote{
    position: relative;
    padding-bottom: 68px;
}

.wrapper-promote .ant-col.bar-actions-promote{
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px 14px;
    width: 100%;
    background-color: #f5f7fa;
    margin: unset;
    z-index: 98;
}

.ant-col.product-base-content{
    padding-left: 20px !important;
}