.plus-circle-outlined {
    margin-left: 6px;
}

.plus-circle-outlined:hover {
    cursor: pointer;
}

.user-panel-name {
    margin-right: 8px;
}

.user-panel-icon {
    margin-right: 8px;
}

.user-panel-icon-action {
    color: initial !important;
    margin-right: 8px;
}

.user-panel-icon-action:hover {
    color: initial !important;
    text-decoration: none !important;
    cursor: pointer;
}