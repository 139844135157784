.ant-form-item {
    margin: 14px !important;
}

.sider {
    border-left: 1px solid #ddd;
    overflow: auto !important;
    height: 100vh !important;
    position: fixed !important;
    right: 0 !important;
    /* top: 64px !important; */
    top: 0 !important;
    max-width: 300px !important;
    background-color: #f5f7fa;
    z-index: 99;
}



.checkbox {
    margin-right: 4px;
}

.filter__button-container .ant-form-item-control-input-content {
    display: flex !important;
    justify-content: flex-end !important;
}