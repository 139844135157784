.styleLoading {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    z-index: 100000;
}

.styleLoading div{
    margin-top: 20%;
}