 .ant-form-horizontal .form-horizontal-now {
    text-align: left;
    width: 100%;
    margin: 13px 0px 10px;
    display: inline-block;
}
 .ant-form-horizontal .form-horizontal-now > div > div{
    margin: 5px 2px !important;
    display: inline-block;
}

 .ant-form-horizontal .form-horizontal-now > div .multi-search-card .ant-card-body > div{
    display: inline-block;
    overflow-x: auto;
    height: 22px;
    max-width: 170px;
}
 .ant-form-horizontal .form-horizontal-now > div .multi-search-card .ant-input{
    height: 22px;
    border: 0px inactiveborder;
    width: 180px;
    display: inline-block;
}
.form-horizontal-now .multi-search-card > div {
    padding: 2px 5px;
}