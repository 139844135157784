.header__icons {
    margin: 0 15px !important;
}

.header__icons:hover {
    cursor: pointer;
}

.header__menu-button {
    color: white !important;
    background: transparent !important;
    border: transparent !important;
    font-size: 16px !important;
}

.header__avatar:hover {
    cursor: pointer !important;
}

.header__module-selector {
    color: white;
    margin-right: 25px;
}

.header__module-selector__text {
    font-size: 18px;
    margin-right: 8px;
}