.table__button-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.table__button-container:hover {
    cursor: pointer;
}

.table__button {
    color: initial;
}

.table__button:hover {
    color: initial;
    text-decoration: none !important;
}

.table__button-container {
    display: flex;
}

.ant-table-tbody > tr > td, 
.ant-table-tbody > tr > th
{
    padding: 0px 8px !important;
}