.ant-select {
    width: 100%;
}

.mx-16 {
    margin: 0 16px;
}

.mt-16 {
    margin-top: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}

.ant-breadcrumb, .ant-breadcrumb-separator {
  color: rgba(0,0,0,.85) !important;
}