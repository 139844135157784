.flex-container {
  display: flex !important;
  align-items: center !important;
}

.justify-content-right {
  display: flex;
  justify-content: right;
}

.styleless-link {
  text-decoration: none !important;
}

.breamcrumb-content {
  margin-bottom: 10px;
}

.spinner-1-container {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.d-flex {
  display: flex !important;
}

.space-between {
  justify-content: space-between !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.ant-layout {
  min-height: 100vh !important;
}

.styleCheckboxLeft .ant-form-item-control-input-content{
  justify-content: left;
}