.ant-layout-sider {
    min-height: 100vh;
    background-color: #f5f7fa !important;
}

.ant-menu {
    background-color: #f5f7fa !important;
}

/* The side navigation menu */
.mobile-menu {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    top: 0; /* Stay at the top */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the mobile-menu */
    background-color: #f5f7fa;
    z-index: 99;
}
  
/* Position and style the close button (top right corner) */
.mobile-menu .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: black;
}
.mobile-menu .header__module-selector {
    margin-left: 24px;
    margin-bottom: 12px;
    color: rgba(0,0,0,.85);
}