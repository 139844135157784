.ant-modal-wrap.modal-csv .ant-modal-body {}

.ant-modal-wrap.modal-csv .ant-modal-body .table-errors-content {
    margin-top: 22px;
    border-top: 1px solid whitesmoke;
    padding-top: 16px;
}

.table-errors-content .btn-export-now a {
    color: rgba(0, 0, 0, 0.85);
    margin: 0 4px;
}

.table-errors-content .btn-export-now:hover a,
.table-errors-content .btn-export-now:focus a {
    color: #40a9ff;
}

.error-labels {
    margin-top: 4px;
    display: inline-block;
}

.form-filter-modal .ant-form.ant-form-horizontal .ant-form-item {
    margin: 8px 0px 11px !important;
}