.styleAddProductBase{
    width: 100%;
    padding: 20px 0;
    text-align: center;
    border: 2px dashed #ccc;
    border-radius: 10px;
    background-color: #f6f6f6;
    cursor: pointer;
}
.styleAddProductBase svg{
    font-size: 40px;
    margin-bottom: 10px;
}
