.styleTabHeader div{
    background-color: #fff;  
    display: inline-block;
    border: 1px solid #2971B8;
    margin-right: 10px;
    width: 200px;
    padding: 5px 5px;
    border-radius: 0px;
    cursor: pointer;
    /*box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);*/

}
.styleTabHeader{
    margin-bottom: 20px;
}

.styleTabHeader div:hover{
    background-color:#ccc ;
    color: #fff;
    border: 1px solid #ccc;
    transition: background-color 0.5s ease, color 0.5s ease
}

.styleTabHeader div:hover h6{
    color: #fff;
    transition: color 0.5s ease;
}

.styleTabHeader div h6{
    color: #2971B8;
    text-align: center;
    font-size: 14px;
    margin: 0;
    text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.2);
}
.selectStatistics{
    background-color:#2971B8 !important;
}
.selectStatistics h6{
    color: #fff !important;
}

.styleDateRangeFilter .inputDateRange {
    display: inline-block;
    margin-right: 10px;
}
.styleDateRangeFilter .inputDateRange label{
    display: block;
    font-size: 14px;
    color: #0d8531;
    font-weight: 600;
}

.styleDateRangeFilter .inputDateRange input{
    border: 2px solid gray !important;
    border-radius: 2px;
}
.buttonFilterStatistics{
    margin-top: 5px;
}
.styleChartDiv div{
    width: 8px;
    height: 8px;
    display: inline-block;
    vertical-align: middle;
}
.styleChartDiv  h6{
    display: inline-block;
    
    vertical-align: middle;
    margin: 0;
    margin-left: 3px;
}
.styleChartDiv{
    display: inline-block;
    margin-right:20px;
}